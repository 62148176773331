import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useUser } from '../../utils/hooks/Auth';
import sessionManangement from '../../utils/userSessionClickEvents';
import { useHistory } from 'react-router';
import PropositionsSlice from './Propositions';
import { propositions } from '../../utils/propositions';
import LoadingSpinner from '../../utils/LoadingSpinner';
import { Auth } from 'aws-amplify';
import { post } from '../../services/Api';
import { en } from '../../utils/translations/en';

/**
 * Subscription Component
 * This component handles the subscription process, including validating the user's plan,
 * displaying the appropriate propositions or error messages, and initiating the Chargebee
 * sign-up process when a proposition is selected.
 */
const Subscription = ({ verify }) => {
  const history = useHistory();
  // Get the authenticated user
  const user = useUser() || {};

  // Extract residential and corporate plans from user attributes
  const {
    'custom:residential-plan': residentialPlan = '',
    'custom:corporate-plan': corporatePlan = ''
  } = user?.attributes || {};

  // Memoize the valid plans array to avoid re-creating the array on every render
  const validPlans = useMemo(
    () => [
      'paygeur',
      'payggbp',
      'bundleeur',
      'bundlegbp',
      'sbeur',
      'sbgbp',
      'elmparkgreen',
      'santryplace',
      'thealliance',
      'walledgarden',
      'stclarespark',
      'taraview',
      'intel',
      'europehotel',
      'nlrathmines',
      'prussiastreet',
      'milnersquare'
    ],
    []
  );

  // Check if the user pre-selected any plan
  const checkStoredPlan = sessionStorage.getItem('plan');

  /**
   * Function to get the plan from session storage or user attributes
   */
  const getPlan = useCallback(() => {
    if (validPlans.includes(checkStoredPlan)) return checkStoredPlan;
    if (residentialPlan) return residentialPlan;
    if (corporatePlan) return corporatePlan;
    return '';
  }, [checkStoredPlan, residentialPlan, corporatePlan, validPlans]);

  // State to store the selected plan and validation status
  const [storedPlan, setStoredPlan] = useState(getPlan());
  const [isValidationSuccessful, setIsValidationSuccessful] = useState(null);
  const [isUserSessionReady, setIsUserSessionReady] = useState(false);

  // Update stored plan when user attributes change
  useEffect(() => {
    setStoredPlan(getPlan());
  }, [getPlan]);

  // Check if the user session is ready
  useEffect(() => {
    if (user.signInUserSession?.idToken?.jwtToken) {
      setIsUserSessionReady(true);
    }
  }, [user.signInUserSession?.idToken?.jwtToken]);

  // Validate the plan if it's an nl plan
  useEffect(() => {
    const validatePlan = async () => {
      if (storedPlan === 'nlrathmines' && isUserSessionReady) {
        try {
          const options = {
            headers: {
              Authorization: user.signInUserSession?.idToken?.jwtToken
            }
          };
          const response = await post(
            `${process.env.REACT_APP_API}/spike/user`,
            {}, // If you need to send a body, replace the empty object
            options
          );
          if (response.data.success) {
            setIsValidationSuccessful(true);
          } else {
            setIsValidationSuccessful(false);
          }
        } catch (error) {
          console.error('Validation error:', error);
          setIsValidationSuccessful(false);
        }
      } else {
        setIsValidationSuccessful(true);
      }
    };
    if (isUserSessionReady) {
      validatePlan();
    }
  }, [
    storedPlan,
    isUserSessionReady,
    user.signInUserSession?.idToken?.jwtToken
  ]);

  // Determine if the plan is residential or corporate
  const isResidential = [
    'elmparkgreen',
    'santryplace',
    'thealliance',
    'walledgarden',
    'stclarespark',
    'taraview',
    'nlrathmines',
    'prussiastreet',
    'milnersquare'
  ].includes(storedPlan);

  const isCorporate = ['intel', 'europehotel'].includes(storedPlan);

  // Handle proposition click
  const handleClick = async e => {
    // Check if session is expired
    if (sessionManangement(user)) {
      await Auth.signOut();
      history.push('/login', { route: 'subscription-session-expired' });
      return;
    }

    // Get user attributes
    const { email, family_name, given_name, phone_number, preferred_username } =
      user?.attributes || {};

    // Get the clicked plan
    const plan = e?.target?.dataset?.cbItem;

    // Initialize Chargebee if not already initialized
    if (!window.Chargebee?.inited) {
      window.Chargebee.init({ site: `${process.env.REACT_APP_CHARGEBEE_ENV}` });
    }

    // Get Chargebee instance and cart
    const cbInstance = window.Chargebee.getInstance();
    const cart = cbInstance.getCart();

    // Set customer data
    cart.setCustomer({
      first_name: given_name,
      last_name: family_name,
      email,
      id: preferred_username,
      phone: phone_number
    });

    // Set checkout success callback
    cbInstance.setCheckoutCallbacks(cart => ({
      success: function () {
        sessionStorage.removeItem('plan');
        verify(true);
      }
    }));

    // Initialize and configure product
    const product = cbInstance.initializeProduct(plan);
    product.incrementPlanQuantity();
    product.isItemsModel = true;
    product.addItem({ item_price_id: plan });
    cart.replaceProduct(product);

    // Proceed to checkout
    cart.proceedToCheckout();
  };

  // Render loading spinner while validation is in progress
  if (isValidationSuccessful === null) {
    return <LoadingSpinner text="Loading..." loading={true} />;
  }

  // Render error message if validation fails
  if (isValidationSuccessful === false) {
    return (
      <section className="flex w-full justify-center pb-6 pt-2 md:pb-24 md:pt-4">
        <div className="border-0 bg-white px-4 rounded-2xl border-ooh-line max-w-ooh-pre-login-cart w-full md:border md:px-6 md:py-10">
          <h2 className="form-title mb-6 md:mb-8">{en.Spike_error_title}</h2>
          <p dangerouslySetInnerHTML={{ __html: en.Spike_error_body }}></p>
        </div>
      </section>
    );
  }

  // Render propositions if validation is successful
  return (
    <section className="py-12 px-4 bg-center md:bg-no-repeat md:bg-bottom lg:py-24 lg:px-0">
      <div className="max-w-5xl mx-auto text-center md:text-left">
        <PropositionsSlice
          slice={propositions}
          handleClick={handleClick}
          storedPlan={storedPlan}
          setStoredPlan={setStoredPlan}
          isResidential={isResidential}
          isCorporate={isCorporate}
        />
      </div>
    </section>
  );
};

export default Subscription;
